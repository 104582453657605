import { useEffect, useRef } from "react";
import { isValidArray, isValidObject } from "../Services/validators";

export const Regex = {
  pinCode: /^[1-9][0-9]{5}$/gm,
  aadhaarNumber: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
  phoneNumber: /^[6-9]\d{9}$/,
  healthId: /^(?=[a-zA-Z])([a-zA-Z0-9]){2,13}[a-zA-Z]$/i,
  // eslint-disable-next-line
  email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
};

export const api = {
  baseUrl:
    process.env.REACT_APP_STAGING === "development"
      ? "https://api.dev.gzero.byepo.com"
      : process.env.REACT_APP_STAGING === "UAT"
      ? "https://api.uat.gzero.byepo.com"
      : process.env.REACT_APP_STAGING === "production"
      ? "https://api.rsbpct.com"
      : "http://127.0.0.1:5000",
};

export const bucketNames = {
  defaultBucket:
    process.env.REACT_APP_STAGING === "development"
      ? "gs://pct-dev-380105.appspot.com"
      : process.env.REACT_APP_STAGING === "UAT"
      ? "gs://pct-uat.appspot.com"
      : process.env.REACT_APP_STAGING === "production"
      ? "gs://pct-prod.appspot.com"
      : "gs://pct-dev-380105.appspot.com",

  surveillanceBucket:
    process.env.REACT_APP_STAGING === "development"
      ? "gs://pct-dev-surveillance"
      : process.env.REACT_APP_STAGING === "UAT"
      ? "gs://pct-uat-surveillance"
      : process.env.REACT_APP_STAGING === "production"
      ? "gs://pct-prod-surveillance"
      : "gs://pct-dev-surveillance",
};

export const logRocketInit = {
  key:
    process.env.REACT_APP_STAGING === "development"
      ? "byepo/gzero-admin-dev"
      : process.env.REACT_APP_STAGING === "UAT"
      ? "byepo/gzero-admin-uat"
      : process.env.REACT_APP_STAGING === "production"
      ? "byepo/gzero-admin-prod"
      : "",
};

export function rippleEffect(event) {
  const parentElement = event.currentTarget;
  const containerElement = event.currentTarget.parentElement;
  const viewportOffset = parentElement.getBoundingClientRect();
  parentElement.classList.add("ripple-element");

  const circle = document.createElement("span");
  circle.classList.add("ripple-span");
  const diameter = Math.max(
    parentElement.clientWidth,
    parentElement.clientHeight
  );
  const radius = diameter / 2;

  circle.style.width = circle.style.height = `${diameter}px`;
  circle.style.left = `${event.clientX +
    containerElement.scrollLeft -
    parentElement.offsetLeft -
    radius}px`;
  circle.style.top = `${event.clientY +
    containerElement?.scrollTop -
    viewportOffset.y -
    radius}px`;
  circle.classList.add("ripples");

  const ripple = parentElement.getElementsByClassName("ripples")[0];

  if (ripple) {
    ripple.remove();
  }
  //Add ripple element
  parentElement.appendChild(circle);
}

export function dateAndTimeConverter(timestamp, type) {
  if (!timestamp) return;

  if (type === "cardDate") {
    let dateObject = new Date(timestamp);
    const date =
      dateObject.toLocaleString("en-IN", { day: "numeric" }) +
      " " +
      dateObject.toLocaleString("en-IN", { month: "short" });
    //   +
    //   " " +
    //   dateObject.toLocaleString("en-IN", { weekday: "long" }) +
    //   " ";
    return date;
  } else {
    if (type === "firstDate") {
      let dateObject = new Date(timestamp);
      const date = dateObject
        .toLocaleString("en-IN", { day: "numeric" })
        .toUpperCase();
      return date;
    }
    if (type === "secondDate") {
      let dateObject = new Date(timestamp);
      const date =
        dateObject.toLocaleString("en-IN", { day: "numeric" }).toUpperCase() +
        " " +
        dateObject.toLocaleString("en-IN", { month: "short" }).toUpperCase() +
        " " +
        dateObject.toLocaleString("en-IN", { year: "numeric" });
      return date;
    }
    if (type === "thirdDate") {
      let dateObject = new Date(timestamp);
      const date =
        dateObject.toLocaleString("en-IN", { day: "numeric" }).toUpperCase() +
        " " +
        dateObject.toLocaleString("en-IN", { month: "short" }) +
        " " +
        dateObject.toLocaleString("en-IN", { year: "numeric" }).substr(2);
      return date;
    }
  }

  if (type === "Time") {
    let dateObject = new Date(timestamp);
    const date = dateObject
      .toLocaleString("en-IN", {
        hour: "numeric" && "2-digit",
        minute: "numeric" && "2-digit",
        hour12: true,
      })
      .toUpperCase();

    // + " ";
    return date;
  }
}

export const filterData = {
  category: {
    0: "community",
    1: "institutional",
    2: "publicSpace",
    3: "transitArea",
  },
  // changesProposed: {
  //   0: "major",
  //   1: "minor",
  //   2: "new",
  // },
  // condition: {
  //   0: "inUse",
  //   1: "renew",
  //   2: "defunct",
  //   3: "demolished",
  // },
  zone: {},
  ward: {},
  status: { 0: "Red", 1: "Green", 2: "Yellow" },
  dateRange: {},
  // quality: {
  //   0: "good",
  //   2: "bad",
  //   1: "renew",
  //   3: "demolished",
  // },
};

export const transformFilterNames = (filterType, items) => {
  function convertLabel(label) {
    if (filterType === "category") {
      switch (label) {
        case "community":
          return "Community";
        case "institutional":
          return "Institutional";
        case "publicSpace":
          return "Public Space";
        case "transitArea":
          return "Transit Area";
        default:
          return "";
      }
    }

    if (filterType === "changesProposed") {
      switch (label) {
        case "major":
          return "Major";
        case "minor":
          return "Minor";
        case "new":
          return "New";
        default:
          return "";
      }
    }

    if (filterType === "condition") {
      switch (label) {
        case "inUse":
          return "In Usage";
        case "renew":
          return "Under Renewal";
        case "defunct":
          return "Defunct";
        case "demolished":
          return "Demolished";
        default:
          return "";
      }
    }

    if (filterType === "quality") {
      switch (label) {
        case "good":
          return "Good";
        case "bad":
          return "Bad";
        case "renew":
          return "Under Renewal";
        case "demolished":
          return "Demolished";
        default:
          return "";
      }
    }
  }

  return items.map((item) => ({
    ...item,
    label: convertLabel(item.label),
  }));
};

export const transformKey = (key) => {
  switch (key) {
    case "category":
      return "Category";
    case "changesProposed":
      return "Changes Proposed";
    case "condition":
      return "Condition";
    case "zone":
      return "Zone";
    case "ward":
      return "Ward";
    case "status":
      return "Status";
    case "dateRange":
      return "Date Range";
    case "quality":
      return "Quality";
    case "issueRaised":
      return "Issue Raised";
    case "issueTakenBy":
      return "Issue Taken By";
    case "procedureName":
      return "Procedure Name";
    case "assignedTo":
      return "Assigned To";
    case "procedureType":
      return "Procedure Type";
    case "PCTNo":
      return "PCT No";
    default:
      return key;
  }
};

export const transformValue = (key) => {
  switch (key) {
    case "community":
      return "Community";
    case "institutional":
      return "Institutional";
    case "publicSpace":
      return "Public Space";
    case "transitArea":
      return "Transit Area";
    case "major":
      return "Major";
    case "minor":
      return "Minor";
    case "new":
      return "New";
    case "inUse":
      return "In Usage";
    case "renew":
      return "Under Renewal";
    case "defunct":
      return "Defunct";
    case "demolished":
      return "Demolished";
    case "good":
      return "Good";
    case "bad":
      return "Bad";
    default:
      return key;
  }
};

export const getKeyDescription = (key) => {
  switch (key) {
    case "category":
      return "Filter according to the category of locations";
    case "changesProposed":
      return "Changes Proposed Description";
    case "condition":
      return "Condition Description";
    case "zone":
      return "Filter according to the zone in which the location is present";
    case "ward":
      return "Filter according to the ward in which the location is present";
    case "status":
      return "Filter according to the status of the location";
    case "dateRange":
      return "Change the date range within which the location data is to be displayed";
    case "quality":
      return "Quality Description";
    default:
      break;
  }
};

export const getMenuTitle = (pathName) => {
  switch (pathName) {
    case "/":
      return "Dashboard";
    case "/admin":
      return "Admin";
    case "/presets":
      return "Presets";
    case "/manager":
      return "Manager";
    default:
      break;
  }
};

export const getValidColorForStatus = (key) => {
  if (key === "Optimal") {
    return "background-color-green";
  } else if (key === "Moderate") {
    return "background-color-yellow";
  } else if (key === "Critical") {
    return "background-color-red";
  } else {
    return "background-color-secondary";
  }
};

export const tableHeadings = {
  index: {
    title: "PCT NO",
    className: "min-width-55px max-width-4-percentage",
  },
  ward: {
    title: "WARD",
    className: "min-width-65px max-width-4-percentage",
  },
  zone: {
    title: "ZONE",
    className: "min-width-65px max-width-4-percentage",
  },
  location: {
    title: "ADDRESS",
    className: "min-width-200px width-30-percentage",
  },
  category: {
    title: "CATEGORY",
    className: "min-width-100px max-width-10-percentage",
  },
  status: {
    title: "STATUS",
    className: "min-width-fit",
  },
  seats: {
    title: "SEATS",
    className:
      "min-width-65px max-width-4-percentage white-space-normal text-align-center",
  },
  openComplaints: {
    title: "RESOLVED COMP.S",
    className:
      "min-width-100px max-width-10-percentage white-space-normal text-align-center",
  },
  closedComplaints: {
    title: "UNRESOLVED COMP.S",
    className:
      "min-width-100px max-width-10-percentage white-space-normal text-align-center",
  },
  completedProcedure: {
    title: "COMPLETED PROCEDURES",
    className:
      "min-width-100px max-width-10-percentage white-space-normal text-align-center",
  },
};

export const checkArraysAreEqual = (arr1, arr2) => {
  if (!isValidArray(arr1) && !isValidArray(arr2)) {
    return true;
  }

  if (arr1?.length !== arr2?.length) {
    return false;
  }

  for (let i = 0; i < arr1?.length; i++) {
    const element1 = arr1[i];
    const element2 = arr2[i];

    if (Array.isArray(element1) && Array.isArray(element2)) {
      if (!checkArraysAreEqual(element1, element2)) {
        return false;
      }
    } else if (typeof element1 === "object" && typeof element2 === "object") {
      if (!checkObjectsAreEqual(element1, element2)) {
        return false;
      }
    } else if (element1 !== element2) {
      return false;
    }
  }

  return true;
};

export const checkObjectsAreEqual = (obj1, obj2) => {
  if (!isValidObject(obj1) && !isValidObject(obj1)) {
    return true;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }

    const value1 = obj1[key];
    const value2 = obj2[key];

    if (typeof value1 === "object" && typeof value2 === "object") {
      if (!checkObjectsAreEqual(value1, value2)) {
        return false;
      }
    } else if (value1 !== value2) {
      return false;
    }
  }

  return true;
};

export const getPublicURL = (documentId) => {
  switch (process.env.REACT_APP_STAGING) {
    case "local":
      return `http://localhost:3002/${documentId}`;

    case "development":
      return `https://public.dev.gzero.byepo.com/${documentId}`;

    case "production":
      return `https://public.rsbpct.com/${documentId}`;

    case "UAT":
      return `https://public.uat.gzero.byepo.com/${documentId}`;

    default:
      return `https://public.dev.gzero.byepo.com/${documentId}`;
  }
};

export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const timeAgo = (timestamp) => {
  const now = new Date();
  const updatedTime = new Date(timestamp);

  const timeDiff = now - updatedTime;

  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);

  if (seconds < 60) {
    return "Just now";
  } else if (minutes < 60) {
    return `${minutes} MIN${minutes === 1 ? "" : "S"}`;
  } else if (hours < 24) {
    return `${hours} HR${hours === 1 ? "" : "S"}`;
  } else if (days < 30) {
    return `${days} DAY${days === 1 ? "" : "S"}`;
  } else {
    return `${months} MONTH${months === 1 ? "" : "S"}`;
  }
};

export const getValidColor = (key) => {
  if (key === "Green") {
    return "background-color-green";
  } else if (key === "Yellow") {
    return "background-color-yellow";
  } else if (key === "Red") {
    return "background-color-red";
  } else {
    return "background-color-red";
  }
};

export const getValidStatus = (key) => {
  if (key === "Green") {
    return "Optimal";
  } else if (key === "Yellow") {
    return "Moderate";
  } else if (key === "Red") {
    return "Critical";
  } else {
    return key;
  }
};

export const getValidStatusByCount = (key) => {
  if (key <= 0) {
    return "Optimal";
  } else if (key > 0 && key <= 5) {
    return "Moderate";
  } else if (key > 5) {
    return "Critical";
  } else {
    throw new Error("Invalid Status Count argument");
  }
};

export const getLastManPowerCaptured = (data) => {
  let status = {};
  const currentDayData =
    data?.dayCount?.[new Date().getFullYear()]?.[new Date().getMonth() + 1]?.[
      new Date().getDate()
    ];

  for (const key in currentDayData) {
    if (key === "totalPercentage") {
      continue;
    }
    status[key] = {
      status: currentDayData[key][currentDayData[key].length - 1] === 1,
    };
  }
  if (
    isValidObject(status) &&
    Object.values(status).every((data) => data.status === true)
  ) {
    return true;
  } else {
    return false;
  }
};

export const getComplaintsCount = (data, type) => {
  return (
    data?.complaintCount?.[new Date().getFullYear()]?.[
      new Date().getMonth() + 1
    ]?.[new Date().getDate()]?.[type] || 0
  );
};

export const getCaptureSummaryPercentage = (data) => {
  return (
    data?.dayCount?.[new Date().getFullYear()]?.[new Date().getMonth() + 1]?.[
      new Date().getDate()
    ]?.totalPercentage || 0
  );
};

export const calculatePercentage = (value, total) => {
  if (total === 0) {
    return 0;
  }
  return Math.round((value / total) * 100);
};

export const calculateTotalPercentage = (item, fromDate, toDate, method) => {
  const methodCount = item?.[method];

  let currentDate = new Date(fromDate).setHours(0, 0, 0);
  const toDateObj = new Date(toDate).setHours(0, 0, 0);

  let totalPercentage = 0;
  let count = 0;

  while (currentDate <= toDateObj) {
    const currentDateObj = new Date(currentDate);
    const year = currentDateObj.getFullYear();
    const month = currentDateObj.getMonth() + 1;
    const day = currentDateObj.getDate();

    const dayData = methodCount?.[year]?.[month]?.[day];
    if (dayData?.hasOwnProperty("totalPercentage")) {
      count++;
      totalPercentage += dayData.totalPercentage;
    }

    currentDate += 86400000;
  }

  return count > 0 ? calculatePercentage(totalPercentage, count * 100) : 0;
};

export const calculateTotal = (item, fromDate, toDate, method, type) => {
  const methodCount = item?.[method];
  const fromDateObj = +new Date(fromDate).setHours(0, 0, 0);
  const toDateObj = +new Date(toDate).setHours(0, 0, 0);
  let total = 0;
  if (methodCount) {
    Object.keys(methodCount).forEach((year) => {
      Object.keys(methodCount?.[year]).forEach((month) => {
        Object.keys(methodCount?.[year][month]).forEach((day) => {
          const currentDate = +new Date(`${year}-${month}-${day}`).setHours(
            0,
            0,
            0
          );
          if (currentDate >= fromDateObj && currentDate <= toDateObj) {
            if (methodCount?.[year][month][day].hasOwnProperty([type])) {
              total += methodCount?.[year][month][day]?.[type];
            }
          }
        });
      });
    });
    return total;
  }
};

export const getStatusCount = (data) => {
  const complaintCount =
    data?.complaintCount?.[new Date().getFullYear()]?.[
      new Date().getMonth() + 1
    ]?.[new Date().getDate()];

  const open = isNaN(complaintCount?.open) ? 0 : complaintCount?.open;
  const closed = isNaN(complaintCount?.closed) ? 0 : complaintCount?.closed;

  const difference = open - closed;

  return difference < 0 ? 0 : difference;
};

export const getCompletedProcedureCount = (data) => {
  return (
    data?.procedureCount?.[new Date().getFullYear()]?.[
      new Date().getMonth() + 1
    ]?.[new Date().getDate()]?.positive +
      data?.procedureCount?.[new Date().getFullYear()]?.[
        new Date().getMonth() + 1
      ]?.[new Date().getDate()]?.negative || 0
  );
};

export const capitalizeFirstLetter = (word) => {
  if (!word) {
    return "N/A";
  }
  const firstLetter = word.charAt(0);

  const firstLetterCap = firstLetter.toUpperCase();

  const remainingLetters = word.slice(1).toLowerCase();

  return firstLetterCap + remainingLetters;
};

export const alignPhoneNumber = (phoneNumber) => {
  if (phoneNumber?.includes(+91)) {
    const num = phoneNumber.slice(3);
    return num.slice(0, 5) + " " + num.slice(5);
  } else if (phoneNumber) {
    return phoneNumber.slice(0, 5) + " " + phoneNumber.slice(5);
  }
};

export const complaintsQueryPath = {
  status: "status.currentStatus",
  issueRaised: "issuedBy.userDetails.phoneNumber",
  issueTakenByPhoneNumber: "takenOverBy.phoneNumber",
  issueTakenByEmail: "takenOverBy.email",
  category: "category",
  ward: "location.ward",
  zone: "location.zone",
  dateRange: "createdAt",
  PCTNo: "locationId",
};

export const proceduresHistoryQueryPath = {
  procedureName: "procedureId",
  assignedTo: "employeeId",
  procedureType: "procedureType",
  dateRange: "createdAt",
};

export const trackQueryPath = {
  date: "timestamp",
};

export const tableTitles = {
  procedures: "PROCEDURES",
  locations: "LOCATIONS",
  workForce: "WORKFORCE",
  preset: "PRESET",
  complaints: "COMPLAINTS",
};

export const transformTableName = (tableName) => {
  switch (tableName) {
    case "LOCATIONS":
      return "locations";
    case "PROCEDURES":
      return "procedures";
    case "COMPLAINTS":
      return "complaints";
    case "WORKFORCE":
      return "workForce";
    default:
      return;
  }
};

export const defaultFilterData = {
  locations: {
    category: [],
    zone: [],
    ward: [],
    status: [],
    dateRange: {
      from: null,
      to: null,
    },
  },
  procedures: {
    procedureName: [],
    assignedTo: [],
    procedureType: [],
    dateRange: {
      from: null,
      to: null,
    },
  },
  workForce: {
    phoneNumber: [],
    assignedTo: [],
    procedureType: [],
    date: null,
  },
  complaints: {
    status: [],
    category: [],
    zone: [],
    ward: [],
    dateRange: {
      from: null,
      to: null,
    },
    issueRaised: [],
    issueTakenBy: [],
    PCTNo: [],
  },
};

export const isDigit = (val) => /^\d+$/.test(val);

export const getComplaintStatusCount = (data, type) => {
  let count = 0;
  if (isValidObject(data)) {
    for (const value of Object.values(data)) {
      if (value?.status?.currentStatus === type?.toUpperCase()) {
        count++;
      }
    }
  }

  return count;
};

export function getUniqueDataFromObj(value, data) {
  const result = [];

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const item = data?.[key];
      if (!result.includes(item?.[value])) {
        result.push(item?.[value]);
      }
    }
  }
  return result;
}

export function getLocationList(locations) {
  if (!isValidObject(locations)) {
    return [];
  }

  const locationsList = [];

  for (const data of Object.values(locations)) {
    locationsList.push(`${data.PCTNo} - ${data.location}`);
  }

  return locationsList.sort((a, b) => {
    let nameA = a.split(" - ")[0];
    let nameB = b.split(" - ")[0];
    if (!nameA) {
      nameA = "N/A";
    }

    if (!nameB) {
      nameB = "N/A";
    }

    //names are digits like - mobile number
    if (isDigit(nameA) && isDigit(nameB)) {
      nameA = parseInt(nameA);
      nameB = parseInt(nameB);
    }

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  });
}

export const locationOptions = (data) => {
  return [
    {
      title: "LOCATION",
      value: data?.location || "N/A",
    },
    {
      title: "ZONE",
      value: data?.zone || "N/A",
    },
    {
      title: "WARD",
      value: data?.ward || "N/A",
    },
    {
      title: "PCT NUMBER",
      value: data?.PCTNo || "N/A",
    },
  ];
};

export const darkModeStyle = [
  { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#263c3f" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#6b9a76" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#38414e" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: "#212a37" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9ca5b3" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#1f2835" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#f3d19c" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#2f3948" }],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#17263c" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#515c6d" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#17263c" }],
  },
];

export const useDeepEqual = (props) => {
  const compareObject = useRef(null);

  function checkEqual(x, y) {
    Object.keys(x).forEach((key) => {
      if (
        typeof x[key] === "object" &&
        typeof y[key] === "object" &&
        x[key] !== null &&
        y[key] !== null
      ) {
        checkEqual(x[key], y[key]);
      } else if (x[key] !== y[key]) {
        // console.table({ key: key, x: x[key], y: y[key] });
      }
    });
  }

  useEffect(() => {
    if (compareObject.current === null) {
      compareObject.current = props;
    }

    if (compareObject.current !== null) {
      checkEqual(compareObject.current, props);
      compareObject.current = props;
    }
    // eslint-disable-next-line
  }, [props]);
};

export const getLastSixMonthsTimestamp = () => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const result = [];
  const currentDate = new Date();

  for (let i = 0; i < 6; i++) {
    const monthStart = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - i,
      1
    ); // First day of the month
    const monthEnd = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - i + 1,
      0
    ); // Last day of the month

    result.push({
      month: monthNames[monthStart.getMonth()],
      start: monthStart.getTime(),
      end: monthEnd.setHours(23, 59, 59),
    });
  }

  return result.reverse(); // Reverse to get the months in chronological order
};

export const getDuration = (startTimestamp, endTimestamp) => {
  const durationMs = endTimestamp - startTimestamp;

  const seconds = Math.floor(durationMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  return {
    totalMilliseconds: durationMs,
    totalSeconds: seconds,
    totalMinutes: minutes,
    totalHours: hours,
    totalDays: days,
    remainingHours: hours % 24,
    remainingMinutes: minutes % 60,
    remainingSeconds: seconds % 60,
  };
};

export function isValidDate(dateString) {
  const regex = /^[1-9]\d{3}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) {
    return false;
  }

  const [year, month, day] = dateString.split("-").map(Number);
  const date = new Date(year, month - 1, day);

  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
}

export function complaintsTableField(data) {
  switch (data) {
    case "COMPLAINT ID":
      return "documentId";
    case "CATEGORY":
      return "category";
    case "PCT NO":
      return "locationId";
    case "RAISED BY":
      return "name";
    case "STATUS":
      return "currentStatus";
    case "RAISED AT":
      return "createdAt";
    case "UPDATED AT":
      return "updatedAt";

    default:
      return data?.toLowerCase();
  }
}

export const complaintsTableHeadings = [
  { title: "PCT NO", width: "80px" },
  { title: "COMPLAINT ID", width: "180px" },
  { title: "CATEGORY", width: "332px" },
  { title: "RAISED BY", width: "180px" },
  { title: "STATUS", width: "150px", textAlign: "center" },
  { title: "RAISED AT", width: "180px" },
  { title: "UPDATED AT", width: "180px" },
];

export function generateAestheticColor() {
  const goldenRatioConjugate = 0.61803398875;

  let hue = Math.random();
  hue += goldenRatioConjugate;
  hue %= 1;

  const saturation = 60 + Math.random() * 20;
  const lightness = 70 + Math.random() * 10;

  hue = Math.floor(hue * 360);

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}
