import { defaultFilterData } from "../../Utils/constants";

const tableReducer = (
  state = {
    loading: false,
    selectedTable: "LOCATIONS",
    activeSwitch: "MAP",
    showModal: {
      summary: null,
      create: null,
    },
    locations: {
      searchKey: "",
      selectedLocation: null,
      filter: defaultFilterData.locations,
    },
    complaints: {
      searchKey: "",
      selectedComplaint: null,
      filter: defaultFilterData.complaints,
    },
    procedures: {
      searchKey: "",
      selectedProcedure: null,
      filter: defaultFilterData.procedures,
    },
    workForce: {
      searchKey: "",
      selectedEmployee: null,
      filter: defaultFilterData.workForce,
    },
  },
  action
) => {
  switch (action.type) {
    case "SET_TABLE_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };

    case "PUT_SELECTED_TABLE":
      return {
        ...state,
        selectedTable: action.payload.selectedTable,
      };

    case "PUT_ACTIVE_SWITCH":
      return {
        ...state,
        activeSwitch: action.payload.activeSwitch,
      };

    case "PUT_SHOW_MODAL":
      return {
        ...state,
        showModal: {
          ...state.showModal,
          ...action.payload.data,
        },
      };

    case "PUT_SELECTED_LOCATION":
      return {
        ...state,
        locations: { ...state.locations, selectedLocation: action.payload.id },
      };

    case "PUT_SELECTED_COMPLAINT":
      return {
        ...state,
        complaints: {
          ...state.complaints,
          selectedComplaint: action.payload.data,
        },
      };

    case "PUT_SELECTED_PROCEDURE":
      return {
        ...state,
        procedures: {
          ...state.procedures,
          selectedProcedure: action.payload.id,
        },
      };

    case "PUT_SELECTED_EMPLOYEE":
      return {
        ...state,
        workForce: {
          ...state.workForce,
          selectedEmployee: action.payload.id,
        },
      };

    case "PUT_LOCATIONS_FILTER":
      return {
        ...state,
        locations: {
          ...state.locations,
          filter: action.payload.filter,
        },
      };

    case "PUT_WORKFORCE_FILTER":
      return {
        ...state,
        workForce: {
          ...state.workForce,
          filter: action.payload.filter,
        },
      };

    case "PUT_COMPLAINTS_FILTER":
      return {
        ...state,
        complaints: {
          ...state.complaints,
          filter: action.payload.filter,
        },
      };

    case "SET_COMPLAINTS_TABLE_SEARCH_KEY":
      return {
        ...state,
        complaints: {
          ...state.complaints,
          searchKey: action.payload.searchKey,
        },
      };

    case "PUT_PROCEDURES_FILTER":
      return {
        ...state,
        procedures: {
          ...state.procedures,
          filter: action.payload.filter,
        },
      };

    case "RESET":
      return state;

    default:
      return state;
  }
};
export default tableReducer;
