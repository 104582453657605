/**
 * @module AuthSaga
 */

import store from "../store";
import { tableActionTypes } from "./saga";

/**
 * set selected table
 * @param {string} selectedTable
 */
export function setSelectedTable(selectedTable) {
  store.dispatch({
    type: tableActionTypes.SET_SELECTED_TABLE,
    payload: { selectedTable: selectedTable },
  });
}

/**
 * set active switch
 * @param {string} activeSwitch
 */
export function setActiveSwitch(activeSwitch) {
  store.dispatch({
    type: tableActionTypes.SET_ACTIVE_SWITCH,
    payload: { activeSwitch: activeSwitch },
  });
}

/**
 * set show modal
 * @param {object} showModal
 */
export function setShowModal(data) {
  store.dispatch({
    type: tableActionTypes.SET_SHOW_MODAL,
    payload: { data: data },
  });
}

/**
 * set selected table
 * @param {string} table
 * @param {string} id
 */
export function setSelectedTableData(table, data) {
  store.dispatch({
    type: tableActionTypes.SET_SELECTED_TABLE_DATA,
    payload: { table: table, data: data },
  });
}

/**
 * set selected table
 * @param {string} table
 * @param {string} filter
 */
export function setTableFilter(table, filter) {
  store.dispatch({
    type: tableActionTypes.SET_TABLE_FILTER,
    payload: { table: table, filter: filter },
  });
}

export function setTableSearchKey(tableName, searchKey) {
  store.dispatch({
    type: tableActionTypes.SET_TABLE_SEARCH_KEY,
    payload: { tableName: tableName, searchKey: searchKey },
  });
}
