const tracksReducer = (
  state = {
    loading: false,
    data: null,
    selectedEmployee: null,
  },
  action
) => {
  switch (action.type) {
    case "SET_TRACKS_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };

    case "PUT_TRACKS_DATA":
      return {
        ...state,
        data: action.payload,
      };

    case "SET_SELECTED_EMPLOYEE":
      return {
        ...state,
        selectedEmployee: action.payload,
      };

    case "CLEAR_TRACKS":
      return {
        ...state,
        data: null,
        selectedEmployee: null,
      };

    case "RESET":
      return {
        loading: false,
        data: null,
        selectedEmployee: null,
      };

    default:
      return state;
  }
};
export default tracksReducer;
