import React from "react";
import { isValidArray } from "../../Services/validators";
import {
  AddIcon,
  AscendingOrderIcon,
  DefaultSortIcon,
  DescendingOrderIcon,
  SummaryIcon,
} from "../../Assets/assets";
import { getValidColorForStatus } from "../../Utils/constants";
import "./Table.css";
import footerImage from "../../Assets/TableFooter.svg";
import CustomScrollBar from "../CustomScrollBar/CustomScrollBar";

export default function Table(props) {
  return (
    <>
      <div className="inherit-parent-height inherit-parent-width position-relative">
        <CustomScrollBar
          horizontal="right"
          onScrollReachedEnd={() => {
            props.onScrollReachedEnd();
          }}
        >
          <div className="inherit-parent-height inherit-parent-width">
            <table
              className="inherit-parent-width"
              style={{
                borderCollapse: "collapse",
                tableLayout: "fixed",
              }}
            >
              <colgroup>
                {isValidArray(props.headers) &&
                  props.headers?.map((data, index) => (
                    <col key={index} style={{ ...data }} />
                  ))}
              </colgroup>
              <thead className="inherit-parent-width ">
                {isValidArray(props.headers) && (
                  <tr
                    style={{ position: "sticky", top: "0", zIndex: "100" }}
                    className={`background-color-dark white-space-no-wrap margin-bottom-larger`}
                  >
                    {props.headers?.map((header, index) => (
                      <th
                        className={`font-size-small background-color-dark font-weight-normal cursor-pointer margin-bottom-default`}
                        key={index}
                        onClick={() => {
                          props.handleSort(header.title);
                        }}
                        data-cy={`table-header-${header?.title}`}
                      >
                        <div
                          className={`table-header-border padding-large display-flex flex-align-items-center flex-justify-content-center `}
                        >
                          <div className="padding-right-default ">
                            {header?.title}
                          </div>

                          {!!props?.sort && (
                            <>
                              {props.sort?.title === header?.title &&
                                props.sort?.ascending && (
                                  <div
                                    data-cy={`${header?.title}-ascending-sort`}
                                    className="height-fit-content width-fit-content"
                                  >
                                    <AscendingOrderIcon
                                      color={
                                        props.theme === "dark"
                                          ? "white"
                                          : "#404040"
                                      }
                                    />
                                  </div>
                                )}

                              {props.sort?.title === header?.title &&
                                !props.sort?.ascending && (
                                  <div
                                    data-cy={`${header?.title}-descending-sort`}
                                    className="height-fit-content width-fit-content flex-align-items-center"
                                  >
                                    <DescendingOrderIcon
                                      color={
                                        props.theme === "dark"
                                          ? "white"
                                          : "#404040"
                                      }
                                    />
                                  </div>
                                )}

                              {(!props.sort?.title ||
                                props.sort?.title !== header?.title) && (
                                <div
                                  data-cy={`${header?.title}-default-sort`}
                                  className="display-flex"
                                >
                                  <DefaultSortIcon
                                    color={
                                      props.theme === "dark"
                                        ? "white"
                                        : "#404040"
                                    }
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                )}
              </thead>
              <tbody className="position-relative">
                <tr>
                  <td>
                    <div
                      style={{
                        height: "24px",
                      }}
                    />
                  </td>
                </tr>
                {isValidArray(props.data) &&
                  props.data?.map((data, index) => (
                    <tr
                      // style={{ height: "34px" }}
                      key={index}
                      className={`on-hover-background-color-table-element ${
                        index % 2 ? "" : "odd-cell-color"
                      } text-align-center font-size-small`}
                      data-cy={`${data?.documentId}-card`}
                      id={data?.documentId}
                    >
                      {isValidArray(props.headers) &&
                        props.headers?.map((header, i) => (
                          <td
                            key={i}
                            style={{ ...header }}
                            className="position-relative inherit-parent-width"
                          >
                            <div
                              className={`table-body-border ${
                                i === props.headers.length - 1
                                  ? "table-body-border-right"
                                  : ""
                              }
                        
                        ${
                          index === props.data.length - 1
                            ? "table-body-border-bottom"
                            : ""
                        } inherit-parent-height inherit-parent-width padding-top-medium padding-medium text-overflow-ellipsis cursor-pointer`}
                              onClick={() => {
                                if (typeof props?.onClick === "function") {
                                  props.onClick(data);
                                }
                              }}
                              data-cy={`${data.documentId}-${
                                header?.title
                              }-${data?.[props.getField(header?.title)] ||
                                "N/A"}`}
                            >
                              <div className="table-corner table-top-right" />
                              {i === 0 && (
                                <div className="table-corner table-top-left" />
                              )}
                              {index === props.data.length - 1 && (
                                <>
                                  {i === 0 && (
                                    <div className="table-corner table-bottom-left" />
                                  )}
                                  <div className="table-corner table-bottom-right" />
                                </>
                              )}

                              {header.title === "STATUS" && props.color && (
                                <div
                                  className={`flex-align-items-center flex-justify-content-center cursor-pointer text-overflow-ellipsis`}
                                  data-cy={`${data.documentId}-STATUS-${data?.status}`}
                                >
                                  <div
                                    style={{
                                      width: "14px",
                                      height: "14px",
                                    }}
                                    className={`border-radius-100-percentage ${getValidColorForStatus(
                                      data?.status
                                    )}`}
                                  />
                                </div>
                              )}
                              {header.title === "LAST EMP. CAP." &&
                                props.color && (
                                  <div
                                    className={`flex-align-items-center flex-justify-content-center cursor-pointer text-overflow-ellipsis`}
                                    data-cy={`${data.documentId}-LAST EMP. CAP.-${data.totalManPowerCapacity}`}
                                  >
                                    <div
                                      style={
                                        data?.totalManPowerCapacity !== "N/A"
                                          ? {
                                              width: "14px",
                                              height: "14px",
                                            }
                                          : {}
                                      }
                                      className={`border-radius-100-percentage ${
                                        data?.totalManPowerCapacity !== "N/A"
                                          ? data?.totalManPowerCapacity
                                            ? "background-color-green"
                                            : "background-color-red"
                                          : ""
                                      }`}
                                    />
                                    {data?.totalManPowerCapacity === "N/A" &&
                                      "N/A"}
                                  </div>
                                )}

                              {!(
                                (header.title === "STATUS" ||
                                  header.title === "LAST EMP. CAP.") &&
                                props.color
                              ) && (
                                <>
                                  {data?.[
                                    props.getField(header?.title)
                                  ]?.toUpperCase() || "N/A"}
                                </>
                              )}
                            </div>
                          </td>
                        ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </CustomScrollBar>

        {props.paginationLoading && (
          <div
            style={{
              width: "calc(100% - 48px)",
            }}
            className="inherit-parent-width flex-justify-content-center flex-align-items-center"
          >
            <div className="flex-justify-content-space-between font-size-medium padding-default">
              <div>Loading...</div>
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          minWidth: "750px",
          width: "75%",
          position: "absolute",
          bottom: "60px",
        }}
      >
        <div className="position-relative inherit-parent-width">
          <div className="position-absolute inherit-parent-width display-flex flex-justify-content-space-between">
            <div
              style={{
                height: "44px",
              }}
              className="padding-left-large font-size-medium display-flex  flex-align-items-center"
            >
              {props.falseData || `DISPLAYING ${props.data.length} RECORDS`}
            </div>

            {props.showCreate && (
              <div
                style={{
                  height: "44px",
                }}
                onClick={props.createClick}
                className="padding-right-large font-size-medium display-flex flex-align-items-center cursor-pointer"
                data-cy="workForce-create-switch"
              >
                <div className="padding-right-default">
                  <AddIcon color="white" />
                </div>
                <div>CREATE</div>
              </div>
            )}
            {props.showSummary && (
              <div
                style={{
                  height: "44px",
                  ...(props.showSummary === "disabled"
                    ? { opacity: "50%" }
                    : {}),
                }}
                onClick={() => {
                  if (props.showSummary !== "disabled") {
                    props.summaryClick();
                  }
                }}
                className="padding-right-large font-size-medium display-flex flex-align-items-center cursor-pointer"
                data-cy={
                  props.showSummary !== "disabled"
                    ? "showSummaryButtonDisabled"
                    : `showSummaryButton`
                }
              >
                <div className="padding-right-default">
                  <SummaryIcon />
                </div>
                <div>SUMMARY</div>
              </div>
            )}
          </div>

          <img
            style={{
              minHeight: "44px",
              height: "auto",
              width: "100%",
              objectFit: "fill",
            }}
            src={footerImage}
            alt="footerImage"
          />
        </div>
      </div>
    </>
  );
}
