import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import {
  getAllComplaintsCount,
  putComplaintsData,
} from "../Redux/complaints/action";
import { setErrorStatus } from "../Redux/status/action";
import { setSelectedTableData } from "../Redux/table/action";
import {
  alignPhoneNumber,
  capitalizeFirstLetter,
  complaintsQueryPath,
  complaintsTableField,
  dateAndTimeConverter,
  isDigit,
  isValidDate,
  tableTitles,
} from "../Utils/constants";
import { exportComplaintsFiles } from "./api";
import { getComplaint } from "./database";
import { database } from "./firebase";
import { getFileUrl } from "./storage";
import { isValidArray, isValidObject } from "./validators";

export default function useComplaints({
  isAuth,
  filterData,
  searchKey,
  locationData,
  accessToken,
  selectedComplaint,
}) {
  const complaintsListener = useRef(null);

  const [complaintsLoading, setComplaintsLoading] = useState({
    assets: false,
    pagination: false,
    search: false,
    export: false,
  });
  const [sortConfig, setSortConfig] = useState({
    title: "",
    ascending: true,
  });
  const currentCursor = useRef(null);
  const dataLimit = 50;

  const [searchData, setSearchData] = useState(null);

  const complaintsData = useRef(null);

  const filterQuery = useRef([]);
  const listeningFilterData = useRef([]);

  const handleComplaintSort = (title) => {
    setSortConfig((prevConfig) => ({
      title,
      ascending: prevConfig.title === title ? !prevConfig.ascending : true,
    }));
  };

  useEffect(() => {
    let query = [];

    Object.keys(filterData).forEach((key) => {
      switch (key) {
        case "dateRange":
          const { from, to } = filterData[key];

          if (from && isValidDate(from) && to && isValidDate(to)) {
            query = [
              ...query,
              complaintsQueryPath[key],
              ">=",
              +new Date(`${filterData[key].from}:00:00:00`),
              complaintsQueryPath[key],
              "<=",
              +new Date(`${filterData[key].to}:23:59:59`),
            ];
          }

          break;

        case "PCTNo":
          if (filterData[key] && locationData) {
            let locationIds = [];

            filterData[key].forEach((PCTNo) => {
              const location = Object.values(locationData).find(
                (data) => data.PCTNo === PCTNo
              );
              if (location?.documentId) {
                locationIds.push(location?.documentId);
              }
            });

            if (isValidArray(locationIds)) {
              query = [...query, complaintsQueryPath[key], "in", locationIds];
            }
          }
          break;

        case "issueTakenBy":
          if (isValidArray(filterData[key])) {
            if (filterData[key]?.[0].includes("@")) {
              query = [
                ...query,
                complaintsQueryPath[`${key}Email`],
                "in",
                filterData[key],
              ];
            } else {
              query = [
                ...query,
                complaintsQueryPath[`${key}PhoneNumber`],
                "in",
                filterData[key],
              ];
            }
          }
          break;

        case "zone":
          if (isValidArray(filterData[key])) {
            query = [...query, complaintsQueryPath[key], "in", filterData[key]];
          }
          break;

        case "ward":
          if (isValidArray(filterData[key])) {
            query = [...query, complaintsQueryPath[key], "in", filterData[key]];
          }
          break;

        default:
          if (isValidArray(filterData[key])) {
            if (filterData[key]) {
              query = [
                ...query,
                complaintsQueryPath[key],
                "in",
                filterData[key],
              ];
            }
          }
          break;
      }
    });
    filterQuery.current = query;

    if (
      typeof complaintsListener.current === "function" &&
      isAuth === true &&
      JSON.stringify(listeningFilterData.current) !==
        JSON.stringify(filterQuery.current)
    ) {
      complaintsData.current = null;
      listeningFilterData.current = filterQuery.current;
      complaintsListener.current();
      complaintsListener.current = subscribeToComplaints();
      getAllComplaintsCount({
        filter: filterQuery.current,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData, locationData]);

  async function getComplaintsAssets(selectedData) {
    try {
      setComplaintsLoading({ ...complaintsLoading, assets: true });
      if (isValidObject(selectedData)) {
        let complaintFileSrc = {};
        let complaintImages = [];

        if (isValidArray(selectedData.assets)) {
          for (let j = 0; j < selectedData?.assets.length; j++) {
            const file = await getFileUrl(selectedData?.assets[j]);

            if (file.type === "image") {
              complaintImages.push(file.url);
            } else if (file.type === "audio") {
              complaintFileSrc = { ...complaintFileSrc, audio: file.url };
            }
          }
        }

        if (selectedData.updates) {
          for (let i = 0; i < selectedData?.updates.length; i++) {
            if (!selectedData.updates[i].assets) {
              selectedData.updates[i].assets = { images: [], audio: null };
            } else {
              if (!selectedData.updates[i].assets.images) {
                selectedData.updates[i].assets.images = [];
              }
              if (!selectedData.updates[i].assets.audio) {
                selectedData.updates[i].assets.audio = null;
              }
            }
            for (let j = 0; j < selectedData?.updates[i]?.proof?.length; j++) {
              const file = await getFileUrl(selectedData?.updates[i].proof[j]);
              if (file.type === "image") {
                selectedData.updates[i].assets.images.push(file.url);
              } else if (file.type === "audio") {
                selectedData.updates[i].assets.audio = file.url;
              }
            }
          }
        }

        let closureFileSrc = {};
        let closureImages = [];
        if (isValidArray(selectedData.closure?.proof)) {
          for (let j = 0; j < selectedData.closure?.proof.length; j++) {
            const file = await getFileUrl(selectedData.closure?.proof[j]);
            if (file.type === "image") {
              closureImages.push(file.url);
            } else if (file.type === "audio") {
              closureFileSrc = { ...closureFileSrc, audio: file.url };
            }
          }
        }

        setSelectedTableData(tableTitles.complaints, {
          ...selectedData,
          fileSrc: {
            ...complaintFileSrc,
            ...(isValidArray(complaintImages)
              ? { images: complaintImages }
              : {}),
          },
          closure: {
            ...selectedData.closure,
            fileSrc: {
              ...closureFileSrc,
              ...(isValidArray(closureImages) ? { images: closureImages } : {}),
            },
          },
        });
      }
      setComplaintsLoading({ ...complaintsLoading, assets: false });
    } catch (error) {
      setComplaintsLoading({ ...complaintsLoading, assets: false });

      console.error("getComplaintsAssets", error);
    }
  }

  // pagination
  const getComplaintsNextPage = async (preLoadedLimit, latestComplaints) => {
    if (
      typeof complaintsListener.current !== "function" ||
      complaintsData.current === null ||
      JSON.stringify(listeningFilterData.current) !==
        JSON.stringify(filterQuery.current)
    ) {
      return;
    }

    setComplaintsLoading({ ...complaintsLoading, pagination: true });
    if (!currentCursor?.current) {
      return setComplaintsLoading({ ...complaintsLoading, pagination: false });
    }
    // OrderBy doesn't work when an alternative key is used with inequality operator
    let paginationQueryRef =
      Array.isArray(listeningFilterData.current) &&
      listeningFilterData.current.some((operator) => {
        return (
          operator === "<" ||
          operator === "<=" ||
          operator === ">" ||
          operator === ">=" ||
          operator === "not-in" ||
          operator === "!="
        );
      })
        ? query(
            collection(database, "complaints"),
            orderBy("createdAt", "desc"),
            startAfter(currentCursor?.current),
            limit(preLoadedLimit !== undefined ? preLoadedLimit : dataLimit)
          )
        : query(
            collection(database, "complaints"),
            orderBy("status.updatedAt", "desc"),
            startAfter(currentCursor?.current),
            limit(preLoadedLimit !== undefined ? preLoadedLimit : dataLimit)
          );
    listeningFilterData.current?.forEach((value, index) => {
      if ((index + 1) % 3 === 0 && index !== 0) {
        paginationQueryRef = query(
          paginationQueryRef,
          where(
            listeningFilterData.current[index - 2],
            listeningFilterData.current[index - 1],
            value
          )
        );
      }
    });

    const querySnapshot = await getDocs(paginationQueryRef);
    let complaints = [];
    querySnapshot.forEach((doc) => {
      complaints.push({
        ...doc.data(),
        documentId: doc.id,
      });
    });

    if (latestComplaints) {
      complaintsData.current = [...latestComplaints, ...complaints];
      convertToTableData(complaintsData.current);
    } else {
      complaintsData.current = [...complaintsData.current, ...complaints];
      convertToTableData(complaintsData.current);
    }

    currentCursor.current = querySnapshot.docs[querySnapshot.docs.length - 1];
    setComplaintsLoading({ ...complaintsLoading, pagination: false });
  };

  // Listener
  const subscribeToComplaints = () => {
    // OrderBy doesn't work when an alternative key is used with inequality operator
    let queryRef =
      Array.isArray(filterQuery.current) &&
      filterQuery.current.some((operator) => {
        return (
          operator === "<" ||
          operator === "<=" ||
          operator === ">" ||
          operator === ">=" ||
          operator === "not-in" ||
          operator === "!="
        );
      })
        ? query(
            collection(database, "complaints"),
            orderBy("createdAt", "desc"),
            limit(dataLimit)
          )
        : query(
            collection(database, "complaints"),
            orderBy("status.updatedAt", "desc"),
            limit(dataLimit)
          );
    filterQuery.current.forEach((value, index) => {
      if ((index + 1) % 3 === 0 && index !== 0) {
        queryRef = query(
          queryRef,
          where(
            filterQuery.current[index - 2],
            filterQuery.current[index - 1],
            value
          )
        );
      }
    });

    return onSnapshot(
      queryRef,
      (dataSnapshot) => {
        let complaints = [];
        dataSnapshot.forEach((doc) => {
          complaints.push({
            ...doc.data(),
            documentId: doc.id,
          });
        });

        currentCursor.current = dataSnapshot.docs[dataSnapshot.docs.length - 1];
        // Check if filters are changed from previously running listener

        if (
          complaintsData.current === null ||
          JSON.stringify(listeningFilterData.current) !==
            JSON.stringify(filterQuery.current)
        ) {
          listeningFilterData.current = filterQuery.current;
          complaintsData.current = complaints;
          convertToTableData(complaints);
          getAllComplaintsCount({
            filter: filterQuery.current,
          });
        } else if (complaintsData.current.length > dataLimit) {
          const previousComplaintsTotal =
            complaintsData.current.length - dataLimit;
          getComplaintsNextPage(previousComplaintsTotal, complaints);
        } else {
          complaintsData.current = complaints;
          convertToTableData(complaints);
          getAllComplaintsCount({
            filter: filterQuery.current,
          });
        }
      },
      (error) => {
        console.error(error, "from complaints listener");
        setErrorStatus(error);
      }
    );
  };

  async function getSearchedComplaint(complaintId) {
    setComplaintsLoading({ ...complaintsLoading, search: true });
    const existData = complaintsData.current?.find(
      (data) => data.documentId === complaintId
    );
    if (isValidObject(existData)) {
      setSearchData(existData);
    } else {
      const searchComplaint = await getComplaint(complaintId);
      if (isValidObject(searchComplaint)) {
        setSearchData(searchComplaint);
      } else {
        setSearchData({});
      }
    }
    setComplaintsLoading({ ...complaintsLoading, search: false });
  }

  const downloadComplaintsReport = async () => {
    try {
      setComplaintsLoading({ ...complaintsLoading, export: true });

      const blob = await exportComplaintsFiles(
        accessToken,
        filterQuery.current
      );

      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;

      link.setAttribute("download", "PCT Location Complaint Counts.xlsx");
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
      setComplaintsLoading({ ...complaintsLoading, export: false });
    } catch (error) {
      console.error("There was an error downloading the file:", error);
    }
  };

  function sortComplaints(data) {
    if (!data) {
      return [];
    }

    if (searchData !== null) {
      if (isValidObject(searchData)) {
        return [searchData];
      } else {
        return [];
      }
    }

    const field = complaintsTableField(sortConfig.title);

    return Object.values(data).sort((a, b) => {
      let nameA, nameB;
      switch (sortConfig?.title) {
        case "COMPLAINT ID":
        case "CATEGORY":
          nameA = a[field]?.toUpperCase() || "N/A";
          nameB = b[field]?.toUpperCase() || "N/A";
          break;
        case "PCT NO":
          nameA = a.location?.PCTNo?.toUpperCase() || "N/A";
          nameB = b.location?.PCTNo?.toUpperCase() || "N/A";
          break;
        case "RAISED BY":
          nameA = a.issuedBy?.userDetails?.[field]?.toUpperCase() || "N/A";
          nameB = b.issuedBy?.userDetails?.[field]?.toUpperCase() || "N/A";
          break;
        case "STATUS":
        case "UPDATED AT":
          nameA = a.status?.[field] || "N/A";
          nameB = b.status?.[field] || "N/A";
          break;
        case "RAISED AT":
          nameA = a?.[field] || "N/A";
          nameB = b?.[field] || "N/A";
          break;
        default:
          return 0;
      }

      if (isDigit(nameA) && isDigit(nameB)) {
        nameA = parseInt(nameA);
        nameB = parseInt(nameB);
      }
      if (nameA < nameB) {
        return sortConfig.ascending ? -1 : 1;
      }
      if (nameA > nameB) {
        return sortConfig.ascending ? 1 : -1;
      }
      return 0;
    });
  }

  function convertToTableData(complaintsData) {
    const sortedComplaints = sortComplaints(complaintsData);

    const complaintTableData = [];
    for (let i = 0; i < sortedComplaints.length; i++) {
      const item = sortedComplaints[i];
      const transformedItem = {
        ...item,
        documentId: item?.documentId,
        locationId: item?.location?.PCTNo?.padStart(2, "0") || "N/A",
        category: item?.category,
        name: isValidObject(item?.issuedBy?.userDetails)
          ? `${alignPhoneNumber(item.issuedBy?.userDetails?.phoneNumber)}, ${
              item.issuedBy?.userDetails?.name
            }`
          : "N/A",
        currentStatus: capitalizeFirstLetter(item?.status?.currentStatus),
        createdAt: item?.createdAt
          ? `${dateAndTimeConverter(
              item?.createdAt,
              "Time"
            )}, ${dateAndTimeConverter(item?.createdAt, "thirdDate")}`
          : "N/A",
        updatedAt: item?.status?.updatedAt
          ? `${dateAndTimeConverter(
              item?.status?.updatedAt,
              "Time"
            )}, ${dateAndTimeConverter(item?.status?.updatedAt, "thirdDate")}`
          : "N/A",
      };
      complaintTableData.push(transformedItem);
    }
    putComplaintsData(complaintTableData);
  }

  useEffect(() => {
    convertToTableData(complaintsData.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortConfig, searchData]);

  useEffect(() => {
    if (!selectedComplaint?.fileSrc || !selectedComplaint?.closure) {
      getComplaintsAssets(selectedComplaint);
    }
    // eslint-disable-next-line
  }, [selectedComplaint]);

  useEffect(() => {
    if (searchKey?.length === 8) {
      getSearchedComplaint(searchKey);
    } else if (searchKey?.length === 0 && searchData !== null) {
      setSearchData(null);
    }
    // eslint-disable-next-line
  }, [searchKey]);

  useEffect(() => {
    if (isAuth === true && complaintsListener.current === null) {
      complaintsListener.current = subscribeToComplaints();
    } else if (
      typeof complaintsListener.current === "function" &&
      isAuth === false
    ) {
      complaintsListener.current();
      complaintsListener.current = null;
    }
    // eslint-disable-next-line
  }, [isAuth]);

  return [
    getComplaintsNextPage,
    getComplaintsAssets,
    complaintsLoading,
    downloadComplaintsReport,
    handleComplaintSort,
    sortConfig,
  ];
}
