import { all, put, takeEvery } from "@redux-saga/core/effects";
import { setErrorStatus } from "../status/action";

export const actionTypes = {
  SET_TRACKS_DATA: "SET_TRACKS_DATA",
  CLEAR_TRACKS_DATA: "CLEAR_TRACKS_DATA",
};

function* getTracksWorker(action) {
  try {
    yield setTracksLoading(true);

    yield put({
      type: "PUT_TRACKS_DATA",
      payload: action.payload.data,
    });

    yield setTracksLoading(false);
  } catch (error) {
    yield setTracksLoading(false);
    setErrorStatus(error);
  }
}

function* clearTracksWorker() {
  yield put({
    type: "CLEAR_TRACKS",
  });
}

export default function* tracksWatcher() {
  yield all([
    takeEvery("SET_TRACKS_DATA", getTracksWorker),
    takeEvery("CLEAR_TRACKS_DATA", clearTracksWorker),
  ]);
}

function* setTracksLoading(bool) {
  yield put({
    type: "SET_TRACKS_LOADING",
    payload: {
      loading: bool,
    },
  });
}
