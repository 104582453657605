import React, { useRef, useState, useEffect } from "react";
import "./CustomScrollBar.css";

const CustomScrollBar = ({ children, onScrollReachedEnd, horizontal }) => {
  const scrollContainerRef = useRef(null);
  const thumbRef = useRef(null);
  const [thumbPosition, setThumbPosition] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [wasAtBottom, setWasAtBottom] = useState(false);

  const handleScroll = () => {
    if (!isDragging) {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = scrollContainerRef.current;
      const thumbHeight = (clientHeight / scrollHeight) * clientHeight;
      const thumbPosition = (scrollTop / scrollHeight) * clientHeight;
      if (thumbRef.current?.style?.height) {
        thumbRef.current.style.height = `${thumbHeight}px`;
      }
      setThumbPosition(thumbPosition);
      checkIfAtBottom(scrollTop, scrollHeight, clientHeight);
    }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    document.body.style.userSelect = "none"; // Prevent text selection while dragging
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    document.body.style.userSelect = ""; // Re-enable text selection after dragging
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const {
        scrollTop,
        clientHeight,
        scrollHeight,
      } = scrollContainerRef.current;
      const thumbHeight = (clientHeight / scrollHeight) * clientHeight;
      const newThumbPosition = Math.min(
        Math.max(
          e.clientY -
            scrollContainerRef.current.getBoundingClientRect().top -
            thumbHeight / 2,
          0
        ),
        clientHeight - thumbHeight
      );

      setThumbPosition(newThumbPosition);
      scrollContainerRef.current.scrollTop =
        (newThumbPosition / clientHeight) * scrollHeight;
      checkIfAtBottom(scrollTop, scrollHeight, clientHeight);
    }
  };

  const checkIfAtBottom = (scrollTop, scrollHeight, clientHeight) => {
    const isAtBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

    if (isAtBottom && !wasAtBottom) {
      setWasAtBottom(true);
      if (onScrollReachedEnd) {
        onScrollReachedEnd();
      }
    } else if (!isAtBottom && wasAtBottom) {
      setWasAtBottom(false);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    scrollContainer.addEventListener("scroll", handleScroll);

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    handleScroll();

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
    // eslint-disable-next-line
  }, [isDragging]);

  const getScrollableStatus = (element) => {
    if (!element) {
      return { vertical: false, horizontal: false };
    }

    const isVerticallyScrollable = element.scrollHeight > element.clientHeight;
    const isHorizontallyScrollable = element.scrollWidth > element.clientWidth;

    return {
      vertical: isVerticallyScrollable,
      horizontal: isHorizontallyScrollable,
    };
  };

  return (
    <div className="custom-scrollbar">
      <div className="scroll-content" ref={scrollContainerRef}>
        {children}
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          ...(horizontal === "left" ? { left: "-60px" } : { right: "-60px" }),
          ...(horizontal === "left" ? { transform: "scaleX(-1)" } : {}),
        }}
        className="inherit-parent-height display-flex"
      >
        <Ruler
          height={scrollContainerRef.current?.clientHeight}
          disabled={!getScrollableStatus(scrollContainerRef.current).vertical}
        />

        <div
          className="scroll-thumb"
          ref={thumbRef}
          style={{ top: `${thumbPosition}px` }}
          onMouseDown={handleMouseDown}
        >
          <div
            className={`arrow${
              !getScrollableStatus(scrollContainerRef.current).vertical
                ? "-disabled"
                : ""
            }`}
            onMouseDown={handleMouseDown}
          >
            &#x25BC;
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomScrollBar;

const Ruler = ({ height, disabled }) => {
  const lineSpacing = 8;
  const numLines = Math.floor(height / lineSpacing);

  return (
    <div className={`ruler${disabled ? "-disabled" : ""}`} style={{ height }}>
      {Array.from({ length: numLines }).map((_, index) => (
        <div
          key={index}
          className={`ruler-line
            ${
              index % 5 === 0
                ? `long-line${disabled ? "-disabled" : ""}`
                : `short-line${disabled ? "-disabled" : ""}`
            }`}
          style={{ top: `${index * lineSpacing}px` }}
        />
      ))}
    </div>
  );
};
